@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_bold';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_medium';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_light';
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "roboto","PingFang SC","Microsoft YaHei";
  line-height: 1.2;
  position: relative;
  color: #fff;
  background-color: $color-main-dark;
  overflow-x: hidden;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
}
.bold{
  font-family: "roboto_bold";
}

//主体内容部分宽度
.container {
  @include res(padding-left,4.16vw,(xs:.3rem));
  @include res(padding-right,4.16vw,(xs:.3rem));
}
.main-blue-bg{
  overflow: hidden;
  background-color: $color-main;
  border-bottom-right-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.common-more{
  display: inline-flex;
  position: relative;
  z-index: 1;
  cursor: pointer;
  .icon{
    position: relative;
    z-index: -1;
    border-radius: 50px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .3s,color .3s, background-color .3s;
    transition-timing-function: cubic-bezier(0.83,-0.06, 0.3, 1.15);
    @include res(width,.48rem,(md:.6rem,sm:.78rem,xs:.7rem));
    @include res(height,.48rem,(md:.6rem,sm:.78rem,xs:.7rem));
    .iconfont{
      @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
    }
    &.icon1{
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(100%);
    }
  }
  .character{
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: #fff;
    color: #000;
    font-family: 'roboto_medium';
    transition: transform .3s,color .3s, background-color .3s;
    transition-timing-function: cubic-bezier(0.83,-0.06, 0.3, 1.15);
    @include res(padding-left,.25rem,(md:.35rem,sm:.45rem,xs:.4rem));
    @include res(padding-right,.25rem,(md:.35rem,sm:.45rem,xs:.4rem));
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
  }
  &:hover{
    .icon{
      // color: #fff;
      background-color: $color-main-light;
      &.icon1{
        transform: translateX(0);
      }
      &.icon2{
        transform: translateX(-100%);
      }
    }
    .character{
      // color: #fff;
      background-color: $color-main-light;
      @include res(transform,translateX(.48rem),(md:translateX(.6rem),sm:translateX(.78rem),xs:translateX(.7rem)));
    }
  }
}