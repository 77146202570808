@import "../../assets/styles/utils";

.nybanner{
  position: relative;
  overflow: hidden;
  @include res(height,7.3rem,(md:8rem,xs:5rem));
  &::before,&::after{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }
  &::before{
    top: 0;
    background-image: linear-gradient(to bottom,rgba($color-main,.7),rgba($color-main,0));
    @include res(height, 30%,(xs:40%));
  }
  &::after{
    bottom: 0;
    background-image: linear-gradient(to top,rgba($color-main,1),rgba($color-main,0));
    @include res(height, 80%);
  }
  .line{
    position: absolute;
    z-index: 2;
    @include res(bottom, .4rem);
    &::after{
      content: '';
      display: block;
      height: 1px;
      background-color: $color-main-light;
    }
  }
  .bg-img{
    height: 100%;
  }
  .t{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    transform: translateY(-50%);
    text-transform: uppercase;
    line-height: 1;
    @include res(letter-spacing,.1rem,(xs:.05rem));
    @include res(font-size,.8rem,(md:.9rem,sm:1rem,xs:.56rem));
    >div{
      display: flex;
      align-items: flex-end;
      &::after{
        content: '';
        flex-shrink: 0;
        display: block;
        background-color: #fff;
        animation: breathe 2s infinite;
        @include res(width,.1rem);
        @include res(height,.1rem);
        @include res(margin-bottom,.15rem);
        @include res(margin-left,.05rem);
      }
    }
  }
}

@keyframes breathe {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}