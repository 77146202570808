@import "../../assets/styles/utils";

footer{
  overflow: hidden;
  background-color: #040924;
  .footer{
    align-items: flex-start;
    justify-content: space-between;
    @include res(padding-top,1.7rem,(xs:1rem));
    @include res(padding-bottom,1.3rem,(xs:.4rem));
    @include res(display,flex,(xs:block));
    .left{
      flex-shrink: 0;
      @include res(text-align, null,(xs:center));
      @include res(width,23.3%,(md:25%,xs:100%));
      @include res(margin-bottom,0,(xs:.6rem));
      .logo{
        display: block;
        @include res(margin-bottom,.95rem,(xs:.5rem));
        img{
          width: auto;
          @include res(height,.66rem,(sm:.8rem,xs:.6rem));
        }
      }
      .t-link{
        @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
        @include res(margin-bottom,.3rem);
      }
      .social-icon{
        a{
          transition: color .3s;
          &:not(:last-child){
            @include res(margin-right, .2rem);
          }
          .iconfont{
            @include res(font-size, .3rem,(md:.46rem,sm:.56rem,xs:.44rem));
          }
          &:hover{
            color: $color-main-light;
          }
        }
      }
    }
    .right{
      flex-shrink: 0;
      @include res(text-align,null,(xs:center));
      @include res(width,65%,(md:65%,sm:70%,xs:100%));
      ul{
        justify-content: space-between;
        width: 100%;
        @include res(display,flex,(xs:block));
        li{
          &:not(:last-child){
            @include res(margin-right,1.15rem,(sm:1rem,xs:0));
            @include res(margin-bottom,0,(xs:.2rem));
          }
          .title{
            white-space: nowrap;
            text-transform: uppercase;
            @include res(margin-bottom,.2rem);
            a{
              transition: color .3s;
              &:hover{
                color: $color-main-light;
              }
            }
          }
          .sub-nav{
            display: flex;
            flex-wrap: wrap;
            white-space: nowrap;
            @include res(font-size, .18rem,(md:.28rem,sm:.34rem,xs:.26rem));
            >div{
              @include res(width,50%,(md:100%));
              @include res(margin-bottom,.15rem);
              a{
                color: rgba(#fff, .6);
                transition: color .3s;
                &:hover{
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .copyright{
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #3b4d67;
    color: rgba(#fff, .6);
    @include res(text-align,null,(xs:center));
    @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.24rem));
    @include res(display,flex,(xs:block));
    @include res(padding-top,.35rem);
    @include res(padding-bottom,.35rem);
    a{
      transition: all .3s;
      &:hover{
        color: #fff;
      }
    }
    .site-map{
      @include res(display,null,(md:none));
    }
  }
}