@import "../../assets/styles/utils";

header{
  .header{
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    @include res(padding-top,.25rem,(sm:0));
    @include res(height,1.35rem,(md:1.65rem,sm:1.6rem,xs:1.1rem));
    &.scroll{
      padding-top: 0;
      // background-color: rgba(#000, .5);
      // backdrop-filter: blur(10px);
      @include res(height,$header-height-base,$header-height-ratio);
      // .bg{
      //   height: 100%;
      // }
    }
    .bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: rgba($color-main-dark, .7);
      z-index: -1;
      backdrop-filter: blur(10px);
      transition: all .3s;
    }
    .logo{
      height: 100%;
      display: flex;
      align-items: center;
      img{
        display: block;
        width: auto;
        transition: all .3s;
        @include res(height,.51rem,(md:.6rem,sm:.7rem,xs:.48rem));
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex);
      .nav{
        height: 100%;
        @include res(display,block,(md:none));
        ul{
          position: relative;
          align-items: center;
          height: 100%;
          @include res(display,flex);
          li{
            height: 100%;
            position: relative;
            .title{
              height: 100%;
              color: #fff;
              transition: color .3s;
              position: relative;
              font-family: 'roboto_bold';
              text-transform: uppercase;
              a{
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                @include res(padding-left,.2rem);
                @include res(padding-right,.2rem);
                &::after{
                  content: '';
                  display: block;
                  background: url(../../assets/images/logo_icon.svg) no-repeat center;
                  background-size: contain;
                  position: absolute;
                  left: 50%;
                  transform: scale(0);
                  transform-origin: center;
                  transition: transform .3s;
                  @include res(width,.9rem,(md:1.4rem));
                  @include res(margin-left,-.45rem,(md:-.7rem));
                  @include res(height,.2rem,(md:.3rem));
                  @include res(bottom,25%,(md:20%));
                }
              }
            }
            .sub-nav{
              position: absolute;
              top: 100%;
              left: .2rem;
              white-space: nowrap;
              opacity: 0;
              visibility: hidden;
              transform: translateY(-.2rem);
              transition: all .3s;
              @include res(padding-bottom,.5rem);
              @include res(font-size, .18rem);
              >div{
                @include res(margin-bottom,.15rem);
                a{
                  transition: color .3s;
                  &:hover{
                    color: $color-main-light;
                  }
                }
                &.active{
                  a{
                    color: $color-main-light;
                  }
                }
              }
            }
            &:hover,&.active{
              .title{
                color: $color-main-light;
                a::after{
                  transform: scale(1);
                }
              }
            }
            &.hover{
              .sub-nav{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
              }
            }
          }
        }
      }
      .right-fun{
        align-items: center;
        justify-content: flex-end;
        @include res(display,flex);
        @include res(top,50%);
        .hamburger{
          @include res(margin-left,.5rem,(xs:.3rem));
          @include res(display,none,(md:block));
        }
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: $color-main-dark;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 3rem,(xs:1.8rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(#fff,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          font-family: 'roboto_bold';
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.4rem,(sm:.5rem,xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .3rem);
          @include res(padding-right, .3rem);
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            display: block;
            clear: both;
          }
          a{
            display: block;
            float: left;
            color: rgba(#fff, .6);
            @include res(width, 50%,(xs:100%));
            @include res(margin-bottom,.2rem);
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}
.form-open{
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  background-color: $color-main-light;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow .3s,transform .3s;
  @include res(right,4.16vw,(xs:.3rem));
  @include res(width,.72rem,(md:.8rem,sm:.96rem,xs:.7rem));
  @include res(height,.72rem,(md:.8rem,sm:.96rem,xs:.7rem));
  @include res(margin-top,-.36rem,(md:-.4rem,sm:-.48rem,xs:0));
  @include res(top,3.65rem,(md:4rem,xs:3.4rem));
  .iconfont{
    @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.4rem));
  }
  &:hover{
    transform: translateY(-.05rem);
    box-shadow: 0 0 .1rem $color-main-light;
  }
}